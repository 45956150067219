export default {
  siteTitle: 'Get bonus',
  blogerName: 'GOMAXWIN',
  socialsList: [],
  projects: [
    {
      name: 'starda',
      url: 'https://stardacasino.life/c743bbbcc',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cbc308174',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c41701617',
      gameTitle: 'Jet Air',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c9e10cce5',
      gameTitle: 'LEGZO PUNK',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c1894a9e5',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c7b48882d',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c204baa65',
      gameTitle: 'Alien Fruits ',
      casinoBonus1: '<strong>150%</strong><div>For the first deposit</div>',
    }
  ],
  headerText: 'Use promo <strong>MAXWIN</strong> to get 50FS in the slots below!',
  yourPromocode: 'Your promo code',
  gameBonus: 'Bonus in',
  promocode: 'MAXWIN',
  casinoBonus1: '<strong>100%</strong><div>For the first deposit</div>',
  casinoBonus2: '<strong>50FS</strong><div>For the sign up with the promo code</div>',
  firstDeposit: 'For the first deposit',
  copyMessage: 'Promo code copied',
  theme: 'dark'
};
